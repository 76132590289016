import React from 'react'
import { Link, } from 'react-router-dom'
import styles from "../../assert/styles/app.module.css";

export default function Header() {

  return (
    <div className={styles.header}>
        <Link to={`/`}>
            <div className={styles.logo} >
                <span>{window.location.hostname}</span>
            </div>
        </Link>
    </div>
  )
}
