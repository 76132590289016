import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import gstyle from "./gamelist.module.css";
import cstyle from "./cate.module.css";

import { Link, useLocation } from "react-router-dom";

import { hotGameList } from "../../../redux/hot_game";
import { gameList } from "../../../redux/game_data";
import { cateList } from "../../../redux/cate_data";
import Header from "../../Header";
import Footer from "../../Footer";
import { getRandomElements, getRandomElementsWithoutUsed } from "../../utils/comm";
import GoogleDisplayAd from "../../GoogleAd/DisplayAd";


export default function Home() {

  const [hotGameDataList, setHotGameDataList]: Array<any> = useState([]);
  const [moreGamgesRowList, setMoreGamgesRowList]: Array<any> = useState([]);
  const [moreGamgesListUI, setMoreGamgesListUI]: Array<any> = useState([])
  const [moreSortList, setMoreSortList]: Array<any> = useState([]);
  const [selectedList, setSelectedList]: Array<any> = useState([]);
  const location = useLocation();
  
  useEffect(() => {
    UImoreGamesList();
  }, [moreGamgesRowList]);

  useEffect(()=>{
     // 组件挂载后，自动滚动到页面顶部
     window.scrollTo(0, 0);
  },[location])

  useEffect(() => {
    document.body.style.overflow = "auto";
    UIhotGamesInit()
    addMoreGames();
    setMoreSortList([]);
  }, []);

  useEffect(()=>{
       // 组件挂载后，自动滚动到页面顶部
       window.scrollTo(0, 0);
  },[])

  const UIhotGamesInit = () => {
    hotGameDataList.length = 0;
    getRandomElements(hotGameList, 3).map((item, index) => {
      hotGameDataList.push({ ...item });
      setHotGameDataList([...hotGameDataList])
    });
  };

  // 渲染更多游戏
  const UImoreGamesList = async () => {
    var num = Math.floor(moreGamgesRowList.length / 6)
    if (num < 1) return;
    const item = (fanzhuan: string, list: Array<any>) => {
      return (
        <div className={gstyle.game_item} key={moreGamgesListUI.length}>
          <div
            className={`${gstyle.game_item_top} ${fanzhuan.length > 0 ? gstyle.fanzhuan : ""}`}
          >
            <div className={`${gstyle.game_item_top_left} ${gstyle.pos_rela}`}>
              <Link to={`games/detail?id=${list[0].id}`}>
                <img src={(`${list[0].path}/${list[0].icon}`)} alt="loading" className={gstyle.big_img} />
              </Link>
            </div>
            <div className={gstyle.game_item_top_right}>
              <div className={gstyle.game_item_top_right_item}>
                <Link to={`games/detail?id=${list[1].id}`}>
                  <img src={(`${list[1].path}/${list[1].icon}`)} alt="loading" className={gstyle.small_img} />
                </Link>
              </div>
              <div className={gstyle.game_item_top_right_item}>
                <Link to={`games/detail?id=${list[2].id}`}>
                  <img src={(`${list[2].path}/${list[2].icon}`)} alt="loading" className={gstyle.small_img} />
                </Link>
              </div>
            </div>
          </div>
          <div className={gstyle.game_item_bottom}>
            <div>
              <Link to={`games/detail?id=${list[3].id}`}>
                <img src={(`${list[3].path}/${list[3].icon}`)} alt="loading" className={gstyle.small_img} />
              </Link>
            </div>
            <div>
              <Link to={`games/detail?id=${list[4].id}`}>
                <img src={(`${list[4].path}/${list[4].icon}`)} alt="loading" className={gstyle.small_img} />
              </Link>
            </div>
            <div>
              <Link to={`games/detail?id=${list[5].id}`}>
                <img src={(`${list[5].path}/${list[5].icon}`)} alt="loading" className={gstyle.small_img} />
              </Link>
            </div>
          </div>
        </div>
      );
    };


    var flag: boolean = moreGamgesListUI.length % 2 === 0;

    for (var i = 0; i < num; i++) {
      moreGamgesListUI.push(item(flag ? "fanzhuan" : "", moreGamgesRowList.slice(i * 6, (i + 1) * 6)));
      setMoreGamgesListUI([...moreGamgesListUI]);
      flag = !flag;
    }

  };

  const addMoreGames = () => {
    moreGamgesRowList.length = 0
    const av = getRandomElementsWithoutUsed(gameList, selectedList, 12)
    selectedList.push([...av])
    setSelectedList([...selectedList])

    av.map((item: any) => {
      moreGamgesRowList.push({ ...item })
      setMoreGamgesRowList([...moreGamgesRowList])
    });
  }


  // 渲染更多分类游戏 headUI
  const cateHeadUI = () => {
    const left = (n: number, c: string) => {
      const item = cateList.cate[n];
      return (
        <div className="pos_rela">
          <Link to={`games/cate?id=${item.id}&name=${item.name}`}>
            <img alt="" src={(`${item.path}/${item.icon}`)} className={cstyle.cate_right_img} />
            <div className={`${cstyle.right_top_title} ${c}`}>{item.name}</div>
          </Link>
        </div>
      );
    };

    const right = (n: number) => {
      const item = cateList.cate[n];
      return (
        <Link to={`games/cate?id=${item.id}&name=${item.name}`}>
          <img
            alt="loading"
            src={(`${item.path}/${item.icon}`)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "0.8rem",
            }}
          />
          <div className={cstyle.cate_left_title}>{item.name}</div>
        </Link>
      );
    };

    return (
      <div className={cstyle.cate_head}>
        <div className={cstyle.cate_right}>
          <div className={cstyle.right_item_top}>
            {left(0, "purple")}
            {left(1, "yellow")}
            {left(2, "pink")}
            {left(3, "green")}
          </div>
        </div>
        <div className={cstyle.cate_left}>{right(4)}</div>
      </div>
    );
  };

  // 更多游戏的列表渲染
  const moreSortClick = () => {
    const cs = ["purple", "yellow", "pink", "green"];
    const item = (n: number, it: any) => {
      return (
        <div key={it.id} className={`${cstyle.cate_more_item} pos_rela`}>
          <Link to={`games/cate?id=${it.id}&name=${it.name}`}>
            <img alt="" src={(`${it.path}/${it.icon}`)} className={cstyle.cate_more_img} />
            <div className={`${cstyle.cate_more_title} ${cs[n % 4]}`}>
              Adventure Games
            </div>
          </Link>
        </div>
      );
    };

    for (let i = 5; i < cateList.cate.length; i++) {
      moreSortList.push(item(i, cateList.cate[i]));
    }
    setMoreSortList([...moreSortList]);
  };

  return (
    <div id="app" className={`${styles.app} app_bg`}>
      <Header></Header>

      <div className={styles.hot_games}>
        <div className={styles.hot_title}>
          <img
            alt=""
            src={require("../../../assert/imgs/hot_icon.png")}
            className={styles.hot_icon}
          />
          <span style={{ marginLeft: "0.6rem" }}>Hot Games</span>
        </div>
        <div className={styles.hot_game_list}>
          {hotGameDataList.map((item: any, index: number) => {
            return <div key={index} className={styles.hot_game_item}>
              <Link key={index} to={`games/detail?id=${item.id}`}>
                <img src={(`${item.path}/${item.icon}`)} alt="" className={styles.hot_img} />
              </Link>
            </div>
          })}</div>
      </div>

      {/* <!-- g1_ad1 --> */}
      {/* <GoogleDisplayAd client="ca-pub-2955823384144718" slot="9342724934" style={{ display: 'block' }}/> */}
      <GoogleDisplayAd client={`${process.env.REACT_APP_ADSENSE_CLIENT_ID}`} slot={`${process.env.REACT_APP_ADSENSE_SLOT}`} style={{ display: 'block' }}/>

      <div className={styles.more_games}>

        <div className={styles.more_game_title}>
          <img alt="" src={require("../../../assert/imgs/game_icon.png")} className={styles.game_icon} />
          <span style={{ marginLeft: "0.6rem" }}>More Games</span>
        </div>

        <div style={{ width: "12.6rem" }}>
          <div className={gstyle.more_game_list}>

            {moreGamgesListUI}
            {/* <!-- g1_ad1 --> */}
            {/* <GoogleDisplayAd client="ca-pub-2955823384144718" slot="9342724934" style={{ display: 'block' }}/> */}
            <GoogleDisplayAd client={`${process.env.REACT_APP_ADSENSE_CLIENT_ID}`} slot={`${process.env.REACT_APP_ADSENSE_SLOT}`} style={{ display: 'block' }}/>
            <div className={gstyle.game_more_btn}>
              <span style={{ fontSize: "0.56rem", color: "rgb(255, 255, 255)", cursor: "pointer", }} onClick={addMoreGames}>
                More Games
                <img src={require("../../../assert/imgs/more_icon.png")} alt="loading" className={gstyle.more_btn} />
              </span>
            </div>

          </div>
        </div>
      </div>

      {/* =================================cute title================= */}
      <div className={styles.cate_title}>
        <img src={require("../../../assert/imgs/cate_icon.png")} alt="loading" className={styles.cate_icon} />
        <span style={{ marginLeft: "0.6rem" }}>Games Sort</span>
      </div>

      {/* ==========================cute more=================== */}
      <div className={cstyle.game_cate}>
        {/* 头部UI */}
        <div className={cstyle.cate_list}>
          {cateHeadUI()}

          {/* 更多 分类游戏 */}
          <div className={cstyle.cate_more}>
            {/* 展开btn */}
            <div className={cstyle.cate_more_btn} style={{ display: moreSortList.length > 0 ? "none" : "block" }} >
              <span style={{ fontSize: "0.56rem", fontWeight: "bold", display: moreSortList.length > 0 ? "none" : "block", }}
                onClick={moreSortClick}>
                More Sort
                <img alt="loading" src={require("../../../assert/imgs/more_icon.png")} className={gstyle.more_btn} />
              </span>
            </div>

            {/* list item */}
            <div className={cstyle.cate_more_list}>{moreSortList}</div>

            {/* close */}
            <div className={cstyle.cate_more_btn} style={{ display: moreSortList.length > 0 ? "block" : "none" }}>
              <span style={{ fontSize: "0.64rem", cursor: 'pointer' }} onClick={() => { setMoreSortList([]); }}>close</span>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== about footer============== */}
      {<Footer />}
    </div>
  );
}
