export const hotGameList = [
  {
    id: "293",
    path: "/games/morelist/293_Bubble_Shooter_Pro",
    name: "Bubble Shooter Pro",
    icon: "game_pic/icon/bubble-shooter-pro-293.webp",
    logo: "game_pic/icon2/bubble-shooter-pro-293.webp",
    image: "game_pic/icon3/bubble-shooter-pro-293.webp",
    icon4: "game_pic/icon4/bubble-shooter-pro-293.webp",
    icon5: "game_pic/icon_large/bubble-shooter-pro-293.webp",
    icon6: "game_pic/icon6/bubble-shooter-pro-293-1.webp",
    playCount: "502148",
    rating: "5.0",
    url: "games/Puzzle_Bobble/",
    icon2Large: "game_pic/icon2_large/bubble-shooter-pro-293.webp",
    icon3Large: "game_pic/icon3_large/bubble-shooter-pro-293.webp",
    categoryName: "",
    categoryId: ",64,84,70,",
    likeRatio: "89",
    likeCount: "192172",
    dislikeCount: "23751",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/bubble-shooter-pro-293.webp",
  },
  {
    id: "27",
    path: "/games/morelist/27_Candy_Time",
    name: "Candy Time",
    icon: "game_pic/icon/candy-time-27.webp",
    logo: "game_pic/icon2/candy-time-27.webp",
    image: "game_pic/icon3/candy-time-27.webp",
    icon4: "game_pic/icon4/candy-time-27.webp",
    icon5: "game_pic/icon_large/candy-time-27.webp",
    icon6: "game_pic/icon6/candy-match-27(1).webp",
    playCount: "518927",
    rating: "4.5",
    url: "games/CandyTime/",
    icon2Large: "game_pic/icon2_large/candy-time-27.webp",
    icon3Large: "game_pic/icon3_large/candy-time-27.webp",
    categoryName: "",
    categoryId: ",64,85,",
    likeRatio: "86",
    likeCount: "182973",
    dislikeCount: "29787",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/candy-time-27.webp",
  },
  {
    id: "1027",
    path: "/games/morelist/1027_Coloruid",
    name: "Coloruid",
    icon: "game_pic/icon/coloruid-1027.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/coloruid-1027.webp",
    icon6: "game_pic/icon6/coloruid-1027.webp",
    playCount: "127980",
    rating: "4.5",
    url: "games/COLOSmiles1027AZBG/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",4,3,",
    likeRatio: "72",
    likeCount: "11978",
    dislikeCount: "4659",
    displayMethod: "",
    icon4Large: "",
  },
  {
    id: "51",
    name: "Smileys",
    icon: "game_pic/icon/smileys-51.webp",
    logo: "game_pic/icon2/smileys-51.webp",
    image: "game_pic/icon3/smileys-51.webp",
    icon4: "game_pic/icon4/smileys-51.webp",
    icon5: "game_pic/icon_large/smileys-51.webp",
    icon6: "",
    playCount: "530976",
    rating: "4.5",
    createdTime: "",
    url: "games/Smileys/",
    icon2Large: "game_pic/icon2_large/smileys-51.webp",
    icon3Large: "game_pic/icon3_large/smileys-51.webp",
    categoryName: "",
    categoryId: ",4,3,75,",
    likeRatio: "81",
    likeCount: "116124",
    dislikeCount: "27239",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/smileys-51.webp",
    path: "/games/morelist/51_Smileys",
  },
  {
    id: "666",
    name: "Fruit Fancy",
    icon: "game_pic/icon/fruit-fancy-666.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/fruit-fancy-666.webp",
    icon6: "game_pic/icon6/fruit-fancy-666.webp",
    playCount: "266033",
    rating: "5.0",
    createdTime: "",
    url: "games/FruitFancy//",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",64,85,",
    likeRatio: "84",
    likeCount: "37989",
    dislikeCount: "7236",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/666_Fruit_Fancy",
  },
  {
    id: "806",
    name: "Cycling Hero",
    icon: "game_pic/icon/cycling-hero-806.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/cycling-hero-806.webp",
    icon6: "game_pic/icon6/cycling-hero-806.webp",
    playCount: "69002",
    rating: "5.0",
    createdTime: "",
    url: "games/CyclingHero/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",6,5,7,",
    likeRatio: "81",
    likeCount: "20679",
    dislikeCount: "4851",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/806_Cycling_Hero",
  },
  {
    id: "1016",
    name: "Golf Master",
    icon: "game_pic/icon/golf-master-1016.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/golf-master-1016.webp",
    icon6: "game_pic/icon6/golf-master-1016.webp",
    playCount: "240702",
    rating: "4.5",
    createdTime: "",
    url: "games/GT1016AZAF/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",5,79,",
    likeRatio: "90",
    likeCount: "67155",
    dislikeCount: "7462",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/1016_Golf_Master",
  },
  {
    id: "1022",
    name: "Anti Gravity Ball 2",
    icon: "game_pic/icon/anti-gravity-ball-2-1022.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/anti-gravity-ball-2-1022.webp",
    icon6: "game_pic/icon6/anti-gravity-ball-2-1022.webp",
    playCount: "604899",
    rating: "4.5",
    createdTime: "",
    url: "games/FGNG1022AZBB/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",4,79,",
    likeRatio: "82",
    likeCount: "158725",
    dislikeCount: "34842",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/1022_Anti_Gravity_Ball_2",
  },
  {
    id: "1028",
    name: "Hole in One",
    icon: "game_pic/icon/hole-in-one-1028.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/hole-in-one-1028.webp",
    icon6: "game_pic/icon6/hole-in-one-1028.webp",
    playCount: "402923",
    rating: "4.5",
    createdTime: "",
    url: "games/GofFi1028AZAh/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",5,7,79,",
    likeRatio: "75",
    likeCount: "84613",
    dislikeCount: "28205",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/1028_Hole_in_One",
  },
  {
    id: "1024",
    name: "Feeding Frenzy",
    icon: "game_pic/icon/feeding-frenzy-1024.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/feeding-frenzy-1024.webp",
    icon6: "game_pic/icon6/feeding-frenzy-1024.webp",
    playCount: "362721",
    rating: "4.5",
    createdTime: "",
    url: "games/MyPets1024AZBD/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",3,82,",
    likeRatio: "70",
    likeCount: "12695",
    dislikeCount: "5441",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/1024_Feeding_Frenzy",
  },
  {
    id: "1063",
    name: "Merge 2048",
    icon: "game_pic/icon/merge-2048-1063.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/merge-2048-1063.webp",
    icon6: "game_pic/icon6/merge-2048-1063_1.webp",
    playCount: "544301",
    rating: "4.5",
    createdTime: "",
    url: "games/2048Sasdadsub/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",72,77,",
    likeRatio: "91",
    likeCount: "178313",
    dislikeCount: "17635",
    displayMethod: "",
    icon4Large: "",
    path: "/games/morelist/1063_Merge_2048",
  },
  {
    id: "478",
    path: "/games/morelist/478_Sum_2048",
    name: "Sum 2048",
    icon: "game_pic/icon/sum-2048-478.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/sum-2048-478.webp",
    icon6: "",
    playCount: "331337",
    rating: "5.0",
    createdTime: "",
    url: "games/Sum2048/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",3,77,72,",
    likeRatio: "89",
    likeCount: "132700",
    dislikeCount: "16401",
    displayMethod: "",
    icon4Large: "",
  },
];
