import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { name: '', loggedIn: false },
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.loggedIn = action.payload.loggedIn;
    },
    clearUser: (state) => {
      state.name = '';
      state.loggedIn = false;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export const getUser = (state:any) => state.user;
export default userSlice.reducer;