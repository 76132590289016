export const cateList = {
  cate: [
    {
      path: "/games/catelist/4_Casual_Games",
      icon: "game_pic/categoryIcon/casual-games.webp",
      name: "Casual Games",
      id: "4",
    },
    {
      path: "/games/catelist/6_Racing_Games",
      icon: "game_pic/categoryIcon/RacingGames.webp",
      name: "Racing Games",
      id: "6",
    },
    {
      path: "/games/catelist/3_Puzzle_Games",
      icon: "game_pic/categoryIcon/PuzzleGames.webp",
      name: "Puzzle Games",
      id: "3",
    },
    {
      path: "/games/catelist/7_Action_Games",
      icon: "game_pic/categoryIcon/action-games.webp",
      name: "Action Games",
      id: "7",
    },
    {
      path: "/games/catelist/5_Sport_Games",
      icon: "game_pic/categoryIcon/sport-games.webp",
      name: "Sport Games",
      id: "5",
    },
    {
      path: "/games/catelist/8_Girls_Games",
      icon: "game_pic/categoryIcon/girls-games.webp",
      name: "Girls Games",
      id: "8",
    },
    {
      path: "/games/catelist/59_Adventure_Games",
      icon: "game_pic/categoryIcon/adventure-games.webp",
      name: "Adventure Games",
      id: "59",
    },
    {
      path: "/games/catelist/60_Shooting_Games",
      icon: "game_pic/categoryIcon/shooting-games.webp",
      name: "Shooting Games",
      id: "60",
    },
    {
      path: "/games/catelist/61_Kids_Games",
      icon: "game_pic/categoryIcon/kids-games.webp",
      name: "Kids Games",
      id: "61",
    },
    {
      path: "/games/catelist/63_Card_Games",
      icon: "game_pic/categoryIcon/card-games.webp",
      name: "Card Games",
      id: "63",
    },
    {
      path: "/games/catelist/64_Match_3_Games",
      icon: "game_pic/categoryIcon/Match3Games.webp",
      name: "Match 3 Games",
      id: "64",
    },
    {
      path: "/games/catelist/65_Arcade_Games",
      icon: "game_pic/categoryIcon/ArcadeGames.webp",
      name: "Arcade Games",
      id: "65",
    },
    {
      path: "/games/catelist/66_Strategy_Games",
      icon: "game_pic/categoryIcon/strategy-games.webp",
      name: "Strategy Games",
      id: "66",
    },
    {
      path: "/games/catelist/67_3D_Games",
      icon: "game_pic/categoryIcon/ThreeDGames.webp",
      name: "3D Games",
      id: "67",
    },
    {
      path: "/games/catelist/68_Running_Games",
      icon: "game_pic/categoryIcon/RunningGames.webp",
      name: "Running Games",
      id: "68",
    },
    {
      path: "/games/catelist/69_Drawing_Games",
      icon: "game_pic/categoryIcon/drawing-games.webp",
      name: "Drawing Games",
      id: "69",
    },
    {
      path: "/games/catelist/70_Bubble_Shooter_Games",
      icon: "game_pic/categoryIcon/bubble-shooter-games.webp",
      name: "Bubble Shooter Games",
      id: "70",
    },
    {
      path: "/games/catelist/72_Number_Games",
      icon: "game_pic/categoryIcon/NumberGames.webp",
      name: "Number Games",
      id: "72",
    },
    {
      path: "/games/catelist/73_Simulator_Games",
      icon: "game_pic/categoryIcon/SimulatorGames.webp",
      name: "Simulator Games",
      id: "73",
    },
    {
      path: "/games/catelist/75_Funny_Crazy_Games",
      icon: "game_pic/categoryIcon/funny-crazy-games.webp",
      name: "Funny&Crazy Games",
      id: "75",
    },
    {
      path: "/games/catelist/76_Line_Games",
      icon: "game_pic/categoryIcon/LineGames.webp",
      name: "Line Games",
      id: "76",
    },
    {
      path: "/games/catelist/77_Merge_Games",
      icon: "game_pic/categoryIcon/merge-games.webp",
      name: "Merge Games",
      id: "77",
    },
    {
      path: "/games/catelist/78_io_Games",
      icon: "game_pic/categoryIcon/io-games.webp",
      name: "io Games",
      id: "78",
    },
    {
      path: "/games/catelist/79_Ball_Games",
      icon: "game_pic/categoryIcon/ball-games.webp",
      name: "Ball Games",
      id: "79",
    },
    {
      path: "/games/catelist/80_War_Games",
      icon: "game_pic/categoryIcon/war-games.webp",
      name: "War Games",
      id: "80",
    },
    {
      path: "/games/catelist/81_Jumping_Games",
      icon: "game_pic/categoryIcon/JumpingGames.webp",
      name: "Jumping Games",
      id: "81",
    },
    {
      path: "/games/catelist/82_Animal_Games",
      icon: "game_pic/categoryIcon/animal-games.webp",
      name: "Animal Games",
      id: "82",
    },
    {
      path: "/games/catelist/83_Defence_Games",
      icon: "game_pic/categoryIcon/defence-games.webp",
      name: "Defence Games",
      id: "83",
    },
    {
      path: "/games/catelist/84_Coloring_Games",
      icon: "game_pic/categoryIcon/ColoringGames.webp",
      name: "Coloring Games",
      id: "84",
    },
    {
      path: "/games/catelist/85_Food_Games",
      icon: "game_pic/categoryIcon/food-games.webp",
      name: "Food Games",
      id: "85",
    },
    {
      path: "/games/catelist/86_Click_Games",
      icon: "game_pic/categoryIcon/ClickGames.webp",
      name: "Click Games",
      id: "86",
    },
    {
      path: "/games/catelist/87_Fashion_Games",
      icon: "game_pic/categoryIcon/FashionGames.webp",
      name: "Fashion Games",
      id: "87",
    },
  ],
};
