import { combineReducers, configureStore } from '@reduxjs/toolkit';
import platformReducer from './platformSlice';
import userReducer from './userSlice'; // 假设这个不需要持久化
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 默认使用 localStorage


// 配置持久化
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['platform'], // 只持久化 'platform'
};

const rootReducer = combineReducers({
  platform: platformReducer, // 这个 reducer 将会被持久化
  user: userReducer, // 这个 reducer 不会被持久化
});

// 创建持久化的 reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: {
    platform: persistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

