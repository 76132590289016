import React from 'react'
import styles from './index.module.css'

import { Route, Routes } from 'react-router-dom'
import Detail from '../Detail'
import Cute from '../Cute'

export default function Games() {
    return (
        <Routes>
            <Route path='detail' element={<Detail />} />
            <Route path='cute' element={<Cute />} />
        </Routes>
    )
}
