import React, { useEffect } from 'react'

import styles from  './index.module.css'
import gstyles from  '../../assert/styles/app.module.css'
import Footer from '../Footer'
import Header from '../Header'

export default function Privacy() {

  useEffect(() => {
    // 组件挂载后，自动滚动到页面顶部
    window.scrollTo(0, 0);
  }, []); // 空数组依赖表示这个副作用只在组件挂载后执行一次

  return (
    <div className={`${gstyles.app} app_bg`}>
      <Header/>
      <div className="content">
        <div
          style={{
            fontSize: "0.96rem",
            color: "black",
            fontWeight: "bold",
            padding: "0.8rem"
          }}

        >
          Privacy Policy
        </div>
        <div
          style={{
            fontSize: "0.68rem",
            color: "#474747",
            lineHeight: "1rem",
            padding: "0 0.8rem",
            marginBottom: "0.8rem",
          }}
        >
          <p>
            This privacy policy (this “Privacy Policy”) explains how our
            Affiliates (“we”, “our” or “us”) collect, use and disclose your
            personal data. This Privacy Policy applies to related services
            (collectively, our “Services”), which informs you of our policies
            regarding the collection, use, and disclosure of personal data when
            you use our Services and the choices you have associated with that
            data. By downloading, accessing, using and/or interacting with our
            Services, you fully understand about our collection, use and
            disclosure of your personal data as described in this Privacy
            Policy. This Privacy Policy is incorporated by reference into the
            end user license agreement available at Terms of Service (the
            “EULA”) and is subject to the provisions of the EULA. Capitalized
            terms used but not defined in this Privacy Policy shall have the
            meaning ascribed to such terms in our EULA. This Privacy Policy will
            help you to understand:
          </p>
          <ol>
            <li>What Personal Data We Collect and How We Use</li>
            <li>Cookies and Similar Technologies</li>
            <li>
              How We Entrust A Third-Party to Process, Share, Transfer and
              Disclose Your Personal Data
            </li>
            <li>How Long We Keep Your Personal Data</li>
            <li>The Security of Your Personal Data</li>
          </ol>
          <h3>What Personal Data We Collect and How We Use</h3>
          <p>
            We offer a diverse mix of online H5 games created by independent
            developers, You can access our service without registering or
            logging in. Certain games on our website are controlled by third
            parties, including third parties located outside the EU/EEA, which
            shall be borne by its own privacy policy respectively. For those
            games hosting by us, we only collect and process your personal data
            in the minimum scope necessary for realizing the following purposes
            based on appropriate legal basis.
          </p>
          <p>
            In order to provide some location-based services such as forming a
            team with people nearby, we may collect your IP address, GPS, RFID,
            your home address. The processing activity for this purpose is based
            on your consent. We will ask for your consent when you turn on this
            feature and you have the right to withdraw your consent at any time.
          </p>
          <p>
            In order to improve our Services and correct bugs or errors that
            occur when you are playing the game, we may collect relevant
            information including but not limited to your behavioral data in the
            Game, device information and network status. The processing activity
            for this purpose is based on our legitimate interests and will not
            override your legal rights and interests.
          </p>
          <p>
            In order to comply with applicable law (such as anti-money
            laundering regulations), we may collect your device and network
            information. The processing activity for this purpose is necessary
            for compliance with the legal obligation we subject to.
          </p>
          <h3>Cookies and Similar Technologies</h3>
          <p>
            We use cookies and other similar technologies (e.g. web beacons, log
            files, scripts and eTags) (“Cookies”) to enhance your experience
            using the Services. Cookies are small files which, when placed on
            your device, enable us to provide certain features and
            functionalities. You have the option to permit installation of such
            Cookies or subsequently disable them. You may accept all cookies, or
            instruct the device or web browser (as applicable) to provide notice
            at the time of installation of cookies, or refuse to accept all
            cookies by adjusting the relevant cookie retention function in your
            device. However, in the event that you refuse to install cookies,
            the Game may be unable to operate as designed.
          </p>
          <h3>
            How We Entrust a Third-Party to Process, Share, Transfer and
            Disclose Your Personal Data
          </h3>
          <p>
            We may share your personal data with the following categories of
            third parties in order to provide our Services to you
          </p>
          <h4>Affiliates</h4>
          <p>
            We are a global company and has affiliates all over the world. In
            order to provide our Services, we will share your personal data with
            our affiliates based on the respective legal basis and data
            processing agreements signed between us.
          </p>
          <h4>Advertising</h4>
          <p>
            This type of service allows User Data to be utilized for advertising
            communication purposes displayed in the form of banners and other
            advertisements on this Website, possibly based on User interests.
            This does not mean that all Personal Data are used for this purpose.
            Information and conditions of use are shown below. Some of the
            services listed below may use Cookies to identify Users or they may
            use the behavioral retargeting technique, i.e. displaying ads
            tailored to the User's interests and behavior, including those
            detected outside this Website. For more information, please check
            the privacy policies of the relevant services. In addition to any
            opt out offered by any of the services below, the User may opt out
            of a third-party service's use of cookies by visiting the Network
            Advertising Initiative opt-out page . （1）AdSense: - Google AdSense
            is an advertising service provided by Google Inc. This service uses
            the "Doubleclick" Cookie, which tracks use of this Website and User
            behavior concerning ads, products and services offered. - Users may
            decide to disable all the Doubleclick Cookies by clicking on:
            google.com/settings/ads/onweb/optout - Personal Data processed:
            Cookies; Usage Data - Place of processing: Ireland - Privacy Policy
            - Opt Out
          </p>
          <h4>Data Analytics</h4>
          <p>
            For purpose of better understand and providing more effective
            service, we may perform research and analysis on the collected
            Personal Data, such as your Game statistics, we may analyze, profile
            and segment such Personal Data. The research and analysis may be
            compiled and analyzed on an aggregate basis. We may also use
            third-party Service Providers to monitor and analyze the use of our
            Service. These third parties have access to your Personal Data only
            to perform these tasks on our behalf and are obligated not to
            disclose or use it for any other purpose. For more information,
            please visit the privacy policies of the partners found here:
            （1）Google Analytics: - Google Analytics is a web analysis service
            provided by Google Inc. ("Google"). Google utilizes the Data
            collected to track and examine the use of this Website, to prepare
            reports on its activities and share them with other Google services.
            - Google may use the Data collected to contextualize and personalize
            the ads of its own advertising network. - Personal Data processed:
            Cookies; Usage Data - Place of processing: Ireland - Privacy Policy
            - Opt Out （2）Google Tag Manager: - Google Tag Manager is a tag
            management service provided by Google Ireland Limited. - Personal
            Data processed: Usage Data. - Place of processing: Ireland - Privacy
            Policy.
          </p>
          <h4>Remarketing and behavioral targeting</h4>
          <p>
            This type of service allows this Website and its partners to inform,
            optimize and serve advertising based on past use of this Website by
            the User. This activity is facilitated by tracking Usage Data and by
            using Trackers to collect information which is then transferred to
            the partners that manage the remarketing and behavioral targeting
            activity. Some services offer a remarketing option based on email
            address lists. In addition to any opt-out feature provided by any of
            the services below, Users may opt out by visiting the Network
            Advertising Initiative opt-out page . Users may also opt-out of
            certain advertising features through applicable device settings,
            such as the device advertising settings for mobile phones or ads
            settings in general. （1）Google Ads Remarketing (Google Ireland
            Limited) - Google Ads Remarketing is a remarketing and behavioral
            targeting service provided by Google Ireland Limited that connects
            the activity of this Website with the Google Ads advertising network
            and the DoubleClick Cookie. Users can opt out of Google's use of
            cookies for ads personalization by visiting Google's Ads Settings .
            - Personal Data processed: Cookies; Usage Data. - Place of
            processing: Ireland - Privacy Policy - Opt Out . （2）Remarketing
            with Google Analytics (Google Ireland Limited) - Remarketing with
            Google Analytics is a remarketing and behavioral targeting service
            provided by Google Ireland Limited that connects the tracking
            activity performed by Google Analytics and its Cookies with the
            Google Ads advertising network and the Doubleclick Cookie. -
            Personal Data processed: Cookies; Usage Data. - Place of processing:
            Ireland - Privacy Policy - Opt Out .
          </p>
          <h4>Traffic optimization and distribution</h4>
          <p>
            This type of service allows this Website to distribute their content
            using servers located across different countries and to optimize
            their performance. Which Personal Data are processed depends on the
            characteristics and the way these services are implemented. Their
            function is to filter communications between this Website and the
            User's browser. Considering the widespread distribution of this
            system, it is difficult to determine the locations to which the
            contents that may contain Personal Information of the User are
            transferred. （1）Cloudflare (Cloudflare Inc.) - Cloudflare is a
            traffic optimization and distribution service provided by Cloudflare
            Inc. - The way Cloudflare is integrated means that it filters all
            the traffic through this Website, i.e., communication between this
            Website and the User's browser, while also allowing analytical data
            from this Website to be collected. - Personal Data processed:
            Cookies; various types of Data as specified in the privacy policy of
            the service. - Place of processing: United States - Privacy Policy.
          </p>
          <h3>How Long We Keep Your Personal Data</h3>
          <h4>How we determine</h4>
          <p>
            We determine the retention period of your personal data taking
            consideration of the following elements:
          </p>
          <ul>
            <li>period of the contractual relationship with you;</li>
            <li>sensitivity of the personal data;</li>
            <li>time elapsed since your last interaction with us;</li>
            <li>security reasons;</li>
            <li>applicable statutes of limitation;</li>
            <li>ongoing or potential litigation or dispute;</li>
            <li>regulatory or legal obligation to retain the personal data.</li>
          </ul>
          <h4>How long we keep your personal data</h4>
          <p>
            We may retain most of your personal data as long as needed to
            provide services or products to you, or as required or permitted by
            applicable laws. When destroying personal data, measures will be
            taken to make the personal data irrecoverable or irreproducible, and
            electronic files which contain personal data will be deleted
            permanently using a technical method which makes the files
            irreproducible. In the event that the processing and retention
            period have terminated, but personal data is required to be retained
            continuously for other reasons including but not limited to for the
            purposes as prescribed under applicable laws, the relevant personal
            data will be stored and maintained separately from other types of
            personal data. If you require us to destroy your personal data
            before the end of its life cycle (as set out in the retention policy
            above), we will destroy your personal data in accordance with local
            laws.
          </p>
          <h3>The Security of Your Personal Data</h3>
          <p>
            We are committed to maintaining the privacy and integrity of your
            personal data no matter where it is stored. Our group companies have
            information security and access policies that limit access to our
            systems and technology. We also protect data through the use of
            technological protection measures such as encryption. Your personal
            data will remain subject to our technical and organizational
            controls and our policies and procedures (including but not limited
            to this Privacy Policy). Unfortunately, the transmission of
            information via the internet is not completely secure. Although we
            will use commercially reasonable efforts to protect your personal
            data, we cannot guarantee the security of the information
            transmitted to our services; any transmission of your information is
            at your own risk. In the case of a personal data breach, we will
            notify the personal data breach to the supervisory authority
            competent, where feasible, not later than 72 hours after having
            become aware of it, unless the personal data breach is unlikely to
            result in a risk to the rights and freedoms of you. When the
            personal data breach is likely to result in a high risk to the
            rights and freedoms of you, we will communicate the personal data
            breach to you without undue delay.
          </p>
        </div>
      </div>

      {<Footer />}
    </div>
  )
}
