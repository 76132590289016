/**
 * 随机获取集合的count个数据
 * @param list 集合
 * @param count 获取随机的数量，不能大于 集合数量
 * @returns 
 */
export function getRandomElements<T>(list: T[], count: number): T[] {
  if (count > list.length) {
    throw new Error("Count exceeds the number of available elements.");
  }

  const shuffled = [...list].sort(() => Math.random() - 0.5); // 打乱数组顺序
  return shuffled.slice(0, count); // 获取前 count 个元素
}




// 获取不存在于 selectedList 中的 count 个随机元素
export function getRandomElementsWithoutUsed<T>(list: T[], selectedList: T[], count: number): T[] {
    if (count > list.length) {
      throw new Error("Count exceeds the number of available elements.");
    }
    
    // 筛选出未被选中的元素
    const availableList = list.filter(
        (item:any) => !selectedList.some((selected:any) => selected.id === item.id)
      );
  
      // 如果可用元素不足 count 个，则返回所有剩余的元素
      if (availableList.length <= count) return availableList;

    const shuffled = [...availableList].sort(() => Math.random() - 0.5); // 打乱数组顺序
    return shuffled.slice(0, count); // 获取前 count 个元素
  }




function getRandomColor() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor.padStart(6, '0')}`;
}

export function getRandomGradient(numColors:number) {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
      colors.push(getRandomColor());
  }
  return `linear-gradient(to right, ${colors.join(', ')})`;
}



