import React from 'react'
import { Link, } from 'react-router-dom'
import styles from './index.module.css'

export default function Footer() {
  
  return (
    <div className={styles.footer_main}>
        <div className={styles.footer_content}>
          <Link to={`/about`}>
            <div className={styles.foot_pd}>About</div>
          </Link>
          <div>|</div>
          <Link to={`/privacy`}>
            <div className={styles.foot_pd}>Privacy Policy</div>
          </Link>
          <div>|</div>
          <Link to={`/contact`}>
            <div className={styles.foot_pd}>Contact</div>
          </Link>
        </div>
      </div>
  )
}
