import React, { useEffect } from 'react'
import { Link } from "react-router-dom";

import mStyles from "./index.module.css";
import styles from "../../assert/styles/app.module.css";
import Footer from '../Footer';
import "./index.module.css"
import Header from '../Header';

export default function Cute() {

  useEffect(() => {
    // 组件挂载后，自动滚动到页面顶部
    window.scrollTo(0, 0);
  }, []); // 空数组依赖表示这个副作用只在组件挂载后执行一次


  return (
    <div className={`${styles.app} app_bg`}>
      <Header/>
      <div className="content">
      </div>
      {<Footer />}
    </div>
  )
}
