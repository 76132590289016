import React, { useEffect } from 'react';

import adstyle from "./ad.module.css";

export default function GoogleDisplayAd(props : { slot: string; style?: React.CSSProperties; client: string }) {
    useEffect(() => {
        // 确保 Google Ads 脚本加载并执行
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("Google Ads script error:", e);
        }
      }, []);
    
      return (
        <div className={adstyle.gads}>
          AD
        <ins
          className="adsbygoogle"
          style={props.style}
          data-ad-client={props.client}
          data-ad-slot={props.slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
        </div>
      );
}
