import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface AppBg{
  key:string;
  value: string;
}

export interface PlatformState {
  value: Array<AppBg>
}

const initialState: PlatformState = {
    value: [{key:'default', value:'linear-gradient(to right, #ed4423, #f3893d, #3bd8a1)'}]
}

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setPlatform: (state:PlatformState, action: PayloadAction<AppBg>) => {
        console.log('Action received:', action, state);
        const others=state.value.filter(i=>i.key !== action.payload.key)
        state.value = [...others, {...action.payload}]
    },
  }
})

export default platformSlice.reducer
export const {setPlatform,} = platformSlice.actions


// 定义 selectors，用于获取状态
export const getPlatform = (state: { platform: {platform:PlatformState }}) =>{
  // console.log(state.platform.platform.value);
  
  const result =  state.platform.platform.value.find(i=> i.key === document.location.hostname);
  return result ? result.value : null; // 返回背景值或 null
} 
