import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';

import RouteHome from './commponents/www/HomeRoute';
import { getRandomGradient } from './commponents/utils/comm';
import  './assert/styles/app.module.css'

import { useDispatch ,useSelector} from 'react-redux';
import { AppBg, getPlatform, setPlatform } from './redux/platformSlice';

function App() {

  //用于设置持久化数据
  const dispatch = useDispatch()
  //用于获取持久化数据
  const saveBg = useSelector(getPlatform)
  const location = useLocation()

  const window_init = () => {
    // 在标准 375px 适配下，100px = 1rem;
    var PixelRatio = 1 / window.devicePixelRatio;

    // 动态设置视口
    // const meta = document.createElement('meta');
    // meta.name = 'viewport';
    // meta.content = `width=device-width, initial-scale=${PixelRatio}, minimum-scale=${PixelRatio}, maximum-scale=${PixelRatio}, user-scalable=no`;
    // document.head.appendChild(meta);

    function setSize() {
      // 动态设置字体大小，自适应
      const html = document.getElementsByTagName('html')[0];
      const pageWidth = html.getBoundingClientRect().width;
      // console.log("----------------", document.getElementsByTagName('html')[0].getBoundingClientRect().width)
      html.style.fontSize = `${pageWidth / 15}px`;
    }

    setSize();
    window.addEventListener('resize', setSize, false); // 自动跳转不需要刷新页面
    window.addEventListener('orientationchange', setSize, false);

    // 清理事件监听器
    return () => {
      window.removeEventListener('resize', setSize);
      window.removeEventListener('orientationchange', setSize);
    };
  }

  
  const setBgcolor = ()=>{
    if(saveBg){
      console.log(saveBg,"----------------------------------------", document.location.pathname);
      return saveBg
    }
    // 生成随机渐变
    const randomGradient = getRandomGradient(3); // 生成 3 种随机颜色的渐变
    dispatch(setPlatform({key: document.location.hostname, value:randomGradient}))
    return randomGradient;
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const element:any = document.querySelector('.app_bg');
      if (element) {
        element.style.background = setBgcolor();
        observer.disconnect(); // 找到元素后停止观察
      }
    });

    // 观察整个 body 的子节点变化
    observer.observe(document.body, { childList: true, subtree: true });

    // 清理观察器
    return () => {
      observer.disconnect();
    };
  }, [location]);

  useEffect(() => {
    
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', `${window.location.hostname} Games site`);
    }

    // console.log("Component mounted or updated");
    window_init();
  }, []);

  return (
      
        <Routes>
          <Route path='/*' element={<RouteHome />} />
          <Route index element={<RouteHome />} />
        </Routes>
      
  );
}

export default App;
